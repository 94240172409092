import React, { useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import useStore, { paymentInfo } from "@/store/"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Heading,
  Text,
  Width,
  Span,
  Link,
  StripeCheckout,
  ShoppingIcon,
} from "@/components/index"

export default function CheckOut() {
  const plan = useStore(state => state.plan)
  const user = useStore(state => state.user)

  useEffect(() => {
    if (!user.email) {
      // user didnt create an account yet
      // redirect them to sign up
      navigate("/sign-up")
    }
  }, [user.email])

  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO title="Create your account" description="" />
      <Box
        bg="agate"
        minheight="100%"
        height="100%"
        z
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["16.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Width
          display="flex"
          flexDirection={["column", null, null, "row"]}
          pt={["0", null, null, "6rem"]}
        >
          <Box
            width={["100%", null, 8 / 12, 6 / 12]}
            textAlign="center"
            mx="auto"
          >
            <Text variant="subhead">Step 2 of 4</Text>
            <Heading level="2" mb="0.8rem">
              Your billing information
            </Heading>
            <Text mb="3.2rem">
              Confirm your selections and add a payment method
            </Text>
            {!user.email && (
              <Text variant="small" color="alertRed" mb="1.8rem">
                You need to create an account first.
              </Text>
            )}
            <Box
              border="1px solid"
              borderColor="#E1E0E3"
              borderRadius="0.4rem"
              p="2.4rem"
              mb="1.2rem"
              display="flex"
              bg="white"
            >
              <Box flex="2" textAlign="left">
                <Text level={2} mt="0" fontWeight="bold" mb="1.2rem">
                  Sonora Guitar Intensive
                </Text>
                <Box display="flex" mb="1.2rem">
                  <ShoppingIcon mr="0.6rem" />
                  <Text level={2} color="amethyst" fontWeight="bold">
                    Free 7-day trial
                  </Text>
                </Box>

                {plan && (
                  <Text level={2}>
                    <strong>Payment plan:</strong> {paymentInfo[plan].name}
                    <Link to="/payment" variant="checkout" ml="0.8rem">
                      EDIT
                    </Link>
                  </Text>
                )}
                {!plan && (
                  <>
                    <Text level={2}>
                      <strong>No payment plan selected yet</strong>
                    </Text>
                    <Link to="/payment" variant="checkout">
                      SELECT PLAN
                    </Link>
                  </>
                )}
              </Box>
              <Box
                flex="1"
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                {plan && (
                  <Text
                    level={3}
                    dangerouslySetInnerHTML={{
                      __html: paymentInfo[plan].amount,
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box
              bg="white"
              border="1px solid"
              borderColor="#E1E0E3"
              borderRadius="0.4rem"
              p="2.4rem"
              mb="1.2rem"
              display="flex"
              justifyContent="space-between"
            >
              <Text level={3} textAlign="left" fontWeight="bold" mb="1.2rem">
                Due today
              </Text>
              <Price>$0.00</Price>
            </Box>
            <Box
              bg="white"
              border="1px solid"
              borderColor="#E1E0E3"
              borderRadius="0.4rem"
              p="2.4rem"
              mb="3.6rem"
            >
              <Text level={3} textAlign="left" fontWeight="bold" mb="1.2rem">
                Credit or debit card
              </Text>
              <StripeCheckout />
            </Box>
          </Box>
        </Width>
        <Width pb={["0", null, null, "13.9rem"]}>
          <Box
            width={["100%", null, null, 6 / 12]}
            textAlign="center"
            mx="auto"
          >
            {plan && (
              <Text
                variant="small"
                mb="3.6rem"
                dangerouslySetInnerHTML={{
                  __html: paymentInfo[plan].smallPrint,
                }}
              />
            )}
            <Text level={2}>
              <Span fontWeight="800">Have questions?</Span>{" "}
              <Link
                to="mailto:contact@learnwithsonora.com"
                as="a"
                color="neutralBlue"
              >
                Contact us
              </Link>
              .
            </Text>
          </Box>
        </Width>
      </Box>
    </Layout>
  )
}

const Price = styled.p`
  color: ${({ theme }) => theme.colors.amethyst};
  font-weight: bold;
  font-size: 2.4rem;
`
